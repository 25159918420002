import './styles'

document.addEventListener('DOMContentLoaded', (event) => {
    function initMap() {
        const address = { lat: 50.978760, lng: 4.572350 };
        const map = new google.maps.Map(document.getElementById('map'), {
            center: address,
            zoom: 16,
            mapId: 'FDM'
        });
        const marker = new google.maps.marker.AdvancedMarkerElement({
            position: address,
            map: map,
            title: 'Feydi de Mooy, Beringstraat 22, 3190 Boortmeerbeek'
        });
    }

    initMap();

    document.addEventListener("click", (event) => {
        const clickover = event.target;
        const _opened = document.querySelector(".navbar-collapse").classList.contains("show");
        if (_opened === true && !clickover.classList.contains("navbar-toggler"))
            document.querySelector("button.navbar-toggler").click();
    });

    document.getElementById("more").addEventListener("click", (event) => {
        const windowHeight = window.innerHeight;
        const navbarHeight = document.querySelector(".navbar").clientHeight;
        const yScrollPosition = windowHeight - navbarHeight;
        window.scrollTo(0, yScrollPosition);
    });

    document.getElementById("top").addEventListener("click", (event) => {
        window.scrollTo(0, 0);
    });
});
